import React, { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { firestore, auth } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Assets
import trophy from "../../../assets/gifs/Trophy.gif";

// interfaces
import { completeChapter, isChapterCompleted } from "../../../utils/chapter";
import { Participant } from "../../../interfaces/Participant";

const CompleteChapter: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();
    const { chapterId, isLibrary } = useParams<{ chapterId: string; isLibrary: string }>();
    const { currentParticipant, currentWeek } = useFetchUser();
    const [isCompleted, setIsCompleted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);

    // Set a flag in localStorage to indicate we're processing a completion
    useEffect(() => {
        // Clear any existing flags first
        localStorage.removeItem('processingCompletion');
        localStorage.removeItem('completionStartTime');
        localStorage.removeItem('completionFinished');
        
        // Set new flags
        localStorage.setItem('processingCompletion', 'true');
        localStorage.setItem('completionStartTime', Date.now().toString());
        
        return () => {
            // Clean up when component unmounts
            localStorage.removeItem('processingCompletion');
            localStorage.removeItem('completionStartTime');
            localStorage.removeItem('completionFinished');
        };
    }, []);

    useEffect(() => {
        if (currentParticipant) {
            handleComplete();
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (isCompleted) {
            console.log("CompleteChapter: Completion is done, will refresh page in 3 seconds");
            
            const timer = setTimeout(() => {
                window.location.reload();
            }, 3000);
            
            return () => clearTimeout(timer);
        }
    }, [isCompleted]);

    const handleComplete = async () => {
        console.log("CompleteChapter: handleComplete called", { chapterId, isLibrary });
        setIsProcessing(true);
        
        if (currentParticipant && currentParticipant.id) {
            if (
                chapterId !== "L9JEcW0LVHgdT90SfPmi" &&
                chapterId !== "dBjDojoMPDWv3gvJCDjt" &&
                chapterId !== "h1BYNJTOhheE8tJ1khUk" &&
                isChapterCompleted(chapterId, currentParticipant)
            ) {
                // Short-circuit if chapter is already completed
                console.log("CompleteChapter: Chapter already completed");
                setIsProcessing(false);
                setIsCompleted(true);
                localStorage.setItem('completionFinished', 'true');
                return redirect();
            } else {
                try {
                    console.log("CompleteChapter: Starting completion process");
                    
                    // Add a delay before starting to ensure we're fully loaded
                    await new Promise(resolve => setTimeout(resolve, 500));
                    
                    console.log("CompleteChapter: Calling completeChapter and updateCompletedChapterCount");
                    const [completeResult, updateResult] = await Promise.all([
                        completeChapter(currentParticipant.id, currentWeek, chapterId),
                        updateCompletedChapterCount(currentParticipant.id, chapterId, currentParticipant.uses)
                    ]);
                    
                    console.log("CompleteChapter: Completion process finished", { completeResult, updateResult });
                    
                    // Show success notification
                    enqueueSnackbar(
                        <span>
                            {t(`1pointCompletedGained`)}
                            <b> 1 point </b>
                            {t(`1pointCompletedChapter`)}
                        </span>,
                        {
                            variant: "success",
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom",
                            },
                            content: (key, message) => (
                                <div key={key} className="point__snackbar">
                                    <img alt="trophy" src={trophy} />
                                    <p>{message}</p>
                                </div>
                            ),
                            autoHideDuration: 5000,
                        }
                    );
                    
                    // Wait for notification to be visible
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    
                    // Mark as completed
                    setIsProcessing(false);
                    setIsCompleted(true);
                    localStorage.setItem('completionFinished', 'true');
                    
                    // Redirect to appropriate page
                    await redirect();
                } catch (error) {
                    console.error("CompleteChapter: Error during completion:", error);
                    // enqueueSnackbar("An error occurred while completing the chapter. Please try again.", { variant: "error" });
                    setIsProcessing(false);
                    setIsCompleted(true); // Mark as completed even on error to avoid getting stuck
                    localStorage.setItem('completionFinished', 'error');
                }
            }
        }
    };

    const redirect = useCallback(async () => {
        console.log("CompleteChapter: redirect() called for chapter:", chapterId);
        try {
            if (isLibrary === "true") {
                return hist.replace("/library");
            }

            // Replace with optimized navigation
            switch (chapterId) {
                case "z9FrQXipd4aNRgDmTzXC":
                    hist.replace("/questionnaires/stage1");
                    break;
                case "L9JEcW0LVHgdT90SfPmi":
                    hist.replace(currentParticipant?.goals["stage1"].length ? "/home" : "/overview");
                    break;
                case "dBjDojoMPDWv3gvJCDjt":
                    hist.replace("/prioritizedTopics");
                    break;
                case "h1BYNJTOhheE8tJ1khUk":
                    hist.replace("/goals");
                    break;
                default:
                    hist.replace("/home");
            }
        } catch (e) {
            console.error("CompleteChapter: Error during redirect:", e);
        }
    }, [chapterId, currentParticipant?.goals, isLibrary, hist]);

    const updateCompletedChapterCount = async (participantId: string, chapterId: string, uses: any[]) => {
        try {
            if (!participantId) {
                console.error("updateCompletedChapterCount: Missing participantId", participantId);
                return;
            }
    
            // Check if uses is an array
            if (!uses || !Array.isArray(uses)) {
                console.error("updateCompletedChapterCount: uses is not an array", uses);
                return;
            }
    
            const participantRef = firebaseDoc(firestore, "Participants", participantId);
            const index = uses.findIndex((u) => !u.end);
    
            if (index !== -1) {
                const updateData: any = {};
    
                if(uses[index]){
                    if (!uses[index].chaptersCompleted) {
                      updateData[`uses.${index}.chaptersCompleted`] = arrayUnion(chapterId);
                    }
                    else{
                      updateData[`uses.${index}.chaptersCompleted`] = arrayUnion(chapterId);
                    }
                    await updateDoc(participantRef, updateData);
                }
            }
        } catch (err: any) {
            console.error("Error in updateCompletedChapterCount:", err.message, err.stack);
        }
    };

    // Show a loading indicator while processing
    if (!isProcessing) {
        return (
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',
                height: '100vh',
                padding: '20px',
                textAlign: 'center'
            }}>
                <h2>Chapter completed successfully!</h2>
                <p>You will be redirected back to the course in a few seconds.</p>
                <p>If you are not redirected automatically, click the button below:</p>
                <button 
                    onClick={() => window.location.reload()} 
                    style={{
                        padding: '10px 20px',
                        marginTop: '20px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px'
                    }}
                >
                    Return to Course
                </button>
            </div>
        );
    }

    return null;
};

export default CompleteChapter;