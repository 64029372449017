import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Mui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Components
import Nav from "../../navigation/nav";
import LoginHero from "../../general/LoginHero";
import Footer from "../../navigation/footer";
import { ForgotPassword } from "./forgotPassword";

// Assets
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import Loading from "../../general/Loading";

// Interfaces
import { Participant } from "../../../interfaces/Participant";

// Utils
import { changeLanguage } from "../../../utils/language";

const Login: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant, fetchingUser, deletedUser } = useFetchUser();

    // States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const getParameterByName = (name: string, url: string = window.location.href): string | null => {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    useEffect(() => {
        const emailFromUrl = getParameterByName('email');
        if (emailFromUrl) {
            console.log("Email from URL:", emailFromUrl);
            setEmail(emailFromUrl);
        }
    }, []);


    const isAllowed = async () => {
        let allowed = false;

        const payload = await getDocs(query(collection(firestore, "Participants"), where("email", "==", email.toLowerCase().trim())));
        const parts = payload.docs.map(genDoc<Participant>());

        if (parts && parts[0] && parts[0].isDeleted) enqueueSnackbar(t("participantDeleted"), { variant: "error" });
        if (parts && parts[0] && parts[0].deactivated && parts[0].stages.stage5) enqueueSnackbar(t("participantDisabled"), { variant: "error" });
        if (!parts || (parts && !parts[0])) enqueueSnackbar(t("participantNotFound"), { variant: "error" });

        if (parts && parts[0] && !parts[0].isDeleted && (!parts[0].deactivated || (parts[0].deactivated && !parts[0].stages.stage5))) allowed = true;

        return allowed;
    };

    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();

            if (await isAllowed()) {
                await signInWithEmailAndPassword(auth, email.toLowerCase().trim(), password);

                // Clear the isLoggedOut cookie
                console.log("Clearing isLoggedOut cookie");
                console.log("document.cookie before:", document.cookie);
                document.cookie = "isLoggedOut=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=brainhealthpro.ca;";

                if (deletedUser) enqueueSnackbar(t("participantDisabled"), { variant: "error" });
            }
        } catch (e) {
            console.error(e);
            console.log("code", e.code);

            if (e.code === "auth/user-not-found") enqueueSnackbar(t("userNotFound"), { variant: "error" });
            if (e.code === "auth/wrong-password") enqueueSnackbar(t("wrongPassword"), { variant: "error" });
            if (e.code === "auth/invalid-email") enqueueSnackbar(t("emailInvalid"), { variant: "error" });
            if (e.code === "auth/network-request-failed") enqueueSnackbar(t("networkError"), { variant: "error" });
        }
    };

    useEffect(() => {
        //    // Clear localStorage
        //    localStorage.clear();

        //    // Clear sessionStorage
        //    sessionStorage.clear();
   
        //    // Clear relevant cookies
        //    document.cookie.split(";").forEach((c) => {
        //        document.cookie = c
        //            .replace(/^ +/, "")
        //            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/;domain=brainhealthpro.ca");
        //    });
   
        if (currentParticipant) {
            // Default language
            if (!localStorage.getItem("language")) changeLanguage(currentParticipant.language);
        }
    }, [currentParticipant]);

    if (fetchingUser) {
        return <Loading loading={fetchingUser} />;
    }

    if (currentParticipant) {
        if (currentParticipant.manualS5) return <Redirect to="manualQuestionnaires/manualS5" />;
        else return <Redirect to="/home" />;
    }

    return (
        <>
            <Nav />
            <LoginHero />
            <div className="login__container">
                <div className="form__container">
                    <h2 className="login__title">{t("loginTitle")}</h2>
                    <form noValidate onSubmit={handleSubmit}>
                        <h6>{t("enterEmail")}</h6>
                        <TextField
                            id="email"
                            margin="normal"
                            placeholder={t("enterEmail")}
                            variant="outlined"
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.currentTarget.value)}
                            required
                            fullWidth
                        />
                        <h6 className="login__password">{t("password")}</h6>
                        <TextField
                            id="password"
                            margin="normal"
                            placeholder={t("password")}
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            onChange={e => setPassword(e.currentTarget.value)}
                            required
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button className="form__forgotPasswordButton" onClick={() => setOpen(true)}>
                            <h6>{t("forgotPassword")}</h6>
                        </Button>
                        <Button className="form__submitButton" type="submit" fullWidth variant="contained">
                            <h6>{t("login")}</h6>
                        </Button>
                    </form>
                </div>
            </div>
            <Footer />
            <ForgotPassword open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default Login;
