// utils/chapter.ts (Corrected - No Custom Interfaces, Using genDoc)
import { firestore } from "../firebase/firebase";
import { doc as firebaseDoc, updateDoc, arrayUnion, getDoc, getDocs, query, collection, doc } from "firebase/firestore";
import { Chapter } from "../interfaces/Chapter";
import { Participant } from "../interfaces/Participant"; // Keep the main Participant interface
import { genDoc } from "../firebase/requests"; // Import genDoc

export const completeChapter = async (participantId: string, currentWeek: number, chapterId: string) => {
    try { // Add try-catch
        // 1. Get participant and chapter data (keeping your original fetching).
        const participantRef = firebaseDoc(firestore, "Participants", participantId);
        const participantSnap = await getDoc(participantRef);
        if (!participantSnap.exists()) {
            throw new Error(`Participant not found: ${participantId}`);
        }
        const participant = participantSnap.data() as Participant; // Use the Participant interface

        const chapterRef = firebaseDoc(firestore, "Chapters", chapterId);
        const chapterSnap = await getDoc(chapterRef);
        if (!chapterSnap.exists()) {
            throw new Error(`Chapter not found: ${chapterId}`);
        }
        const chapter = chapterSnap.data() as Chapter;

        if (participant && participant.id && chapter.id) {
            // Initialize history objects if they don't exist (using optional chaining - more concise)
            const completedChaptersHistory = participant.completedChaptersHistory ?? {
                "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            };
            const unlockedChaptersHistory = participant.unlockedChaptersHistory ?? {
                "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            };

            const completedChapters: any = participant.completedChapters || {
                "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            };
            const unlockedChapters: any = participant.unlockedChapters || {
               "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            }


            // --- Core Logic (Keeping your structure, but with CRITICAL fixes) ---

            // Use arrayUnion for correct array updates.  This is *much* safer
            // than spreading and pushing, especially with realtime listeners.
            const updateData: any = {
                [`completedChapters.${chapter.topic}`]: arrayUnion(chapter.id), // Use arrayUnion
                points: (participant.points || 0) + 1, // Use existing value or 0
            };

            // Conditional updates (using arrayUnion and keyof)
            if (!unlockedChapters[chapter.topic as keyof typeof unlockedChapters]?.includes(chapterId)) {
                updateData[`unlockedChapters.${chapter.topic}`] = arrayUnion(chapterId);
            }


            if (!unlockedChaptersHistory[chapter.topic as keyof typeof unlockedChaptersHistory]?.some((x: any) => x.id === chapterId)) {

                // Initialize if it does not exist
                if (!participant.unlockedChaptersHistory) {
                  participant.unlockedChaptersHistory = {} as any; // Initialize
                }
                if (!participant.unlockedChaptersHistory[chapter.topic]) {
                     participant.unlockedChaptersHistory[chapter.topic] = []
                }

                // Use arrayUnion
                updateData[`unlockedChaptersHistory.${chapter.topic}`] = arrayUnion({ id: chapterId, date: new Date() });
            }

            if (!completedChaptersHistory[chapter.topic as keyof typeof completedChaptersHistory]?.some((x: any) => x.id === chapterId)) {
               // Initialize if it does not exist
                if (!participant.completedChaptersHistory) {
                  participant.completedChaptersHistory = {} as any; // Initialize
                }
                if (!participant.completedChaptersHistory[chapter.topic]) {
                     participant.completedChaptersHistory[chapter.topic] = []
                }
                // Use arrayUnion
                updateData[`completedChaptersHistory.${chapter.topic}`] = arrayUnion({ id: chapterId, date: new Date() });
            }

            const weekKey = `week${currentWeek < 10 ? `0${currentWeek}` : currentWeek}`; // Define BEFORE use
             if (currentWeek === 0 || currentWeek === 1) {
                updateData[`timeline.${weekKey}`] = arrayUnion(chapter.id);
            }

            // *** AWAIT THE UPDATE *** (Critical Fix)
            await updateDoc(participantRef, updateData);


           // Check previous week's completion
            if (currentWeek >= 2) {
                if (!(await weeklyChapterCompleted(currentWeek - 1, participantId))) {
                    await updateDoc(participantRef, { weeksInRow: 0 });
                }
            }

            // Must be one of this week's chapter
            if (
                participant.timeline &&
                (participant.timeline as any)[weekKey] &&
                (participant.timeline as any)[weekKey].includes(chapter.id) //weekKey was used after
            ) {
                // Check the week's completion
                if (await weeklyChapterCompleted(currentWeek, participantId)) {
                    await updateDoc(participantRef, { weeksInRow: (participant.weeksInRow || 0) + 1 });
                }
            }
        }
    } catch (error) {
        console.error("Error completing chapter:", error);
        throw error; // Re-throw to be caught by Promise.all
    }
};

export const weeklyChapterCompleted = async (week: number, participantId: string) => {
    try{
      const participantRef = firebaseDoc(firestore, "Participants", participantId);
      const participantSnap = await getDoc(participantRef);
      if (!participantSnap.exists()) {
          throw new Error(`Participant not found: ${participantId}`);
      }
       const participant = genDoc<Participant>()(participantSnap); //Fix
  
      const weekKey = `week${week < 10 ? `0${week}` : week}`;
      if (!participant.timeline || !(participant.timeline as any)[weekKey]) {
          return false; // No chapters assigned for this week
      }
  
      const assignedChapterIds = (participant.timeline as any)[weekKey];
      let completedCount = 0;
  
      // Iterate through assigned chapters, check if they are in *any* completedChapters entry
      for (const assignedChapterId of assignedChapterIds) {
        //Iterating this way because topic are a finite list
          for (const topic of Object.keys(participant.completedChapters || {})) { // Use optional chaining
              if ((participant.completedChapters as any)[topic]?.includes(assignedChapterId)) { // Use optional chaining
                  completedCount++;
                  break; // Found in completedChapters, move to next assigned chapter
              }
          }
      }
       // Determine required completion count based on week
      let requiredCount = 0;
      if (week === 1) {
          requiredCount = 1;
      } else if (week === 2) {
          requiredCount = 3;
      } else if (week === 3) {
          requiredCount = 5;
      } else {
          requiredCount = 4;
      }
  
  
      return completedCount >= requiredCount;
    } catch(error:any){
        console.error("Error in weeklyChapterCompleted", error.message, error.stack) //Include stack
          throw error; // Re-throw to be caught by Promise.all
    }
  };

export const isChapterCompleted = (id: string, currentParticipant: Participant | null) => {
    let isCompleted = false;
    if (currentParticipant) {
        Object.values(currentParticipant.completedChapters).forEach(c => {
            if (c.length > 0 && c.includes(id)) isCompleted = true;
        });
    }

    return isCompleted;
};

export const getChapterCompletionDate = (chapterId: string, participant: Participant | null) => {
    let date: Date | null = null;

    if (participant && participant.uses && participant.uses.length) {
        participant.uses.forEach(u => {
            if (chapterId && u.chaptersCompleted && u.chaptersCompleted.includes(chapterId)) {
                date = u.end;
            }
        });
    }

    return date;
};

export const isChapterFavorited = (chapterId: string, participant: Participant | null) => {
    let favorited = false;

    if (participant && participant.favourites && participant.favourites.length) {
        favorited = participant.favourites.includes(chapterId);
    }

    return favorited;
};

export const getChapterVisitedCount = (chapterId: string, participant: Participant | null) => {
    let count = 0;

    if (participant && participant.uses && participant.uses.length) {
        participant.uses.forEach(u => {
            if (u.chaptersVisited && u.chaptersVisited.includes(chapterId)) {
                count++;
            }
        });
    }

    return count;
};

export const areAllChaptersCompleted = async (participantId: string) => {
    const participantPayload = await getDoc(doc(firestore, "Participants", participantId));
    const participant = genDoc<Participant>()(participantPayload);

    if (!participant) throw new Error("Invalid participant id");

    const payload = await getDocs(query(collection(firestore, "Chapters")));
    const chapters = payload.docs.map(genDoc<Chapter>());

    const sleepChapters = chapters.filter(x => x.topic === "Sleep");
    const vascularChapters = chapters.filter(x => x.topic === "Vascular Health");
    const visionHearingChapters = chapters.filter(x => x.topic === "Vision Hearing");
    const brainOverviewChapters = chapters.filter(x => x.topic === "Brain Overview");
    const cognitiveChapters = chapters.filter(x => x.topic === "Cognitive");
    const nutritionChapters = chapters.filter(x => x.topic === "Nutrition");
    const physicalChapters = chapters.filter(x => x.topic === "Physical");
    const socialChapters = chapters.filter(x => x.topic === "Social Psychological");

    // console.log("-------------- Completed -----------------");
    // console.log("sleep", `${participant.completedChapters["Sleep"].length}/${sleepChapters.length}`);
    // console.log("vascular", `${participant.completedChapters["Vascular Health"].length}/${vascularChapters.length}`);
    // console.log("visionHearing", `${participant.completedChapters["Vision Hearing"].length}/${visionHearingChapters.length}`);
    // console.log("brainOverview", `${participant.completedChapters["Brain Overview"].length}/${brainOverviewChapters.length}`);
    // console.log("cognitive", `${participant.completedChapters["Cognitive"].length}/${cognitiveChapters.length}`);
    // console.log("nutrition", `${participant.completedChapters["Nutrition"].length}/${nutritionChapters.length}`);
    // console.log("physical", `${participant.completedChapters["Physical"].length}/${physicalChapters.length}`);
    // console.log("social", `${participant.completedChapters["Social Psychological"].length}/${socialChapters.length}`);

    // console.log("-------------- Stages -----------------");
    // console.log("stage1", participant.stages.stage1);
    // console.log("stage2", participant.stages.stage2);
    // console.log("stage3", participant.stages.stage3);
    // console.log("stage4", participant.stages.stage4);
    // console.log("stage5", participant.stages.stage5);

    // console.log(
    //     "allChapterCompleted",
    //     participant.completedChapters["Brain Overview"].length === brainOverviewChapters.length &&
    //         JSON.stringify(participant.completedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Cognitive"].length === cognitiveChapters.length &&
    //         JSON.stringify(participant.completedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Nutrition"].length === nutritionChapters.length &&
    //         JSON.stringify(participant.completedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Physical"].length === physicalChapters.length &&
    //         JSON.stringify(participant.completedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Sleep"].length === sleepChapters.length &&
    //         JSON.stringify(participant.completedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Social Psychological"].length === socialChapters.length &&
    //         JSON.stringify(participant.completedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Vascular Health"].length === vascularChapters.length &&
    //         JSON.stringify(participant.completedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Vision Hearing"].length === visionHearingChapters.length &&
    //         JSON.stringify(participant.completedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    // );

    return (
        participant.completedChapters["Brain Overview"].length === brainOverviewChapters.length &&
        JSON.stringify(participant.completedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Cognitive"].length === cognitiveChapters.length &&
        JSON.stringify(participant.completedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Nutrition"].length === nutritionChapters.length &&
        JSON.stringify(participant.completedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Physical"].length === physicalChapters.length &&
        JSON.stringify(participant.completedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Sleep"].length === sleepChapters.length &&
        JSON.stringify(participant.completedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Social Psychological"].length === socialChapters.length &&
        JSON.stringify(participant.completedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Vascular Health"].length === vascularChapters.length &&
        JSON.stringify(participant.completedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Vision Hearing"].length === visionHearingChapters.length &&
        JSON.stringify(participant.completedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    );
};

export const areAllChaptersUnlocked = async (participantId: string) => {
    const participantPayload = await getDoc(doc(firestore, "Participants", participantId));
    const participant = genDoc<Participant>()(participantPayload);

    if (!participant) throw new Error("Invalid participant id");

    const payload = await getDocs(query(collection(firestore, "Chapters")));
    const chapters = payload.docs.map(genDoc<Chapter>());

    const sleepChapters = chapters.filter(x => x.topic === "Sleep");
    const vascularChapters = chapters.filter(x => x.topic === "Vascular Health");
    const visionHearingChapters = chapters.filter(x => x.topic === "Vision Hearing");
    const brainOverviewChapters = chapters.filter(x => x.topic === "Brain Overview");
    const cognitiveChapters = chapters.filter(x => x.topic === "Cognitive");
    const nutritionChapters = chapters.filter(x => x.topic === "Nutrition");
    const physicalChapters = chapters.filter(x => x.topic === "Physical");
    const socialChapters = chapters.filter(x => x.topic === "Social Psychological");

    // console.log("-------------- Unlocked -----------------");
    // console.log("sleep", `${participant.unlockedChapters["Sleep"].length}/${sleepChapters.length}`);
    // console.log("vascular", `${participant.unlockedChapters["Vascular Health"].length}/${vascularChapters.length}`);
    // console.log("visionHearing", `${participant.unlockedChapters["Vision Hearing"].length}/${visionHearingChapters.length}`);
    // console.log("brainOverview", `${participant.unlockedChapters["Brain Overview"].length}/${brainOverviewChapters.length}`);
    // console.log("cognitive", `${participant.unlockedChapters["Cognitive"].length}/${cognitiveChapters.length}`);
    // console.log("nutrition", `${participant.unlockedChapters["Nutrition"].length}/${nutritionChapters.length}`);
    // console.log("physical", `${participant.unlockedChapters["Physical"].length}/${physicalChapters.length}`);
    // console.log("social", `${participant.unlockedChapters["Social Psychological"].length}/${socialChapters.length}`);

    // console.log("-------------- Stages -----------------");
    // console.log("stage1", participant.stages.stage1);
    // console.log("stage2", participant.stages.stage2);
    // console.log("stage3", participant.stages.stage3);
    // console.log("stage4", participant.stages.stage4);
    // console.log("stage5", participant.stages.stage5);

    // console.log(
    //     "allChaptersUnlocked",
    //     participant.unlockedChapters["Brain Overview"].length === brainOverviewChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Cognitive"].length === cognitiveChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Nutrition"].length === nutritionChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Physical"].length === physicalChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Sleep"].length === sleepChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Social Psychological"].length === socialChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Vascular Health"].length === vascularChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Vision Hearing"].length === visionHearingChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    // );

    return (
        participant.unlockedChapters["Brain Overview"].length === brainOverviewChapters.length &&
        JSON.stringify(participant.unlockedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Cognitive"].length === cognitiveChapters.length &&
        JSON.stringify(participant.unlockedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Nutrition"].length === nutritionChapters.length &&
        JSON.stringify(participant.unlockedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Physical"].length === physicalChapters.length &&
        JSON.stringify(participant.unlockedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Sleep"].length === sleepChapters.length &&
        JSON.stringify(participant.unlockedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Social Psychological"].length === socialChapters.length &&
        JSON.stringify(participant.unlockedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Vascular Health"].length === vascularChapters.length &&
        JSON.stringify(participant.unlockedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Vision Hearing"].length === visionHearingChapters.length &&
        JSON.stringify(participant.unlockedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    );
};
