import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { AdminUser } from "../interfaces/AdminUser";
import { Participant } from "../interfaces/Participant";

// Firebase
import { auth } from "../firebase/firebase";

// Hooks
import useDb from "../hooks/useDb";

// Utils
import { weeksBetween } from "../utils/date";
import { signInWithCustomToken } from "firebase/auth";
import { set } from "react-hook-form";

const initState = {
    currentAdmin: null,
    currentParticipant: null,
    currentWeek: 0,
    fetchingUser: true,
    loading: false,
    // eslint-disable-next-line
    setLoading: (value: any) => {
        //
    },
    resetStates: () => {
        //
    },
    deletedUser: false,
    // eslint-disable-next-line
    setDeletedUser: (value: any) => {
        //
    },
};

const useFetchUserImpl = () => {
    // States
    const [loading, setLoading] = useState(false);
    const [currentAdmin, setCurrentAdmin] = useState<AdminUser | null>(null);
    const [currentParticipant, setCurrentParticipant] = useState<Participant | null>(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);
    const [deletedUser, setDeletedUser] = useState(false);

    const adminUserRequests = useDb<AdminUser>("Users", currentAdmin);
    const participantRequests = useDb<Participant>("Participants", currentAdmin);

    const resetStates = () => {
        setCurrentParticipant(null);
        setCurrentAdmin(null);
        setCurrentWeek(0);
        setFetchingUser(false);
        setLoading(false);
        setDeletedUser(false);
    };

    const getCookie = (name: string): string | undefined => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift();
    };

    const checkAuthToken = async () => {
        const authToken = getCookie('authToken');
        if (authToken && !auth.currentUser) {
            try {
                console.log(authToken);
                console.log("testing token here")
                await signInWithCustomToken(auth, authToken);
                console.log("Authenticated with custom token from cookie/localStorage");
                return true;
            } catch (error) {
                console.error("Error signing in with custom token:", error);
                return false;
            }
        }
        return false;
    };

    useEffect(() => {
        let unsubParticipant: any = null;
        let unsubAdmin: any = null;

        const fetchUser = async (user: any) => {
            try {
                if (!user) {
                    await checkAuthToken();
                    setFetchingUser(false);
                    return;
                }

                console.log("Auth state changed:", user); // DEBUG
                setFetchingUser(true);

                if (user.displayName === "Participants") {
                    unsubParticipant = participantRequests.onSnapshot({
                        callback: (participant: Participant) => {
                            if (participant && !participant.isDeleted) {
                                setCurrentParticipant(participant);
                                setCurrentAdmin(null);

                                if (participant.cycleStart) {
                                    const today = new Date();
                                    setCurrentWeek(today >= participant.cycleStart ? weeksBetween(today, participant.cycleStart) : 1);
                                }
                            } else {
                                setDeletedUser(true);
                            }
                            setFetchingUser(false);
                        },
                        id: user.uid,
                    });
                } else {
                    unsubAdmin = adminUserRequests.onSnapshot({
                        callback: (adminUser: AdminUser) => {
                            setCurrentAdmin(adminUser);
                            setCurrentParticipant(null);
                            setFetchingUser(false);
                        },
                        id: user.uid,
                    });
                }
            } catch (error) {
                console.error("Error in fetchUser:", error);
                setFetchingUser(false);
            }
        };

        const checkLogout = async () => {
            const isLoggedOut = getCookie('isLoggedOut');
            if (isLoggedOut === 'true') {
                try {
                    await auth.signOut();
                } catch (error) {
                    console.error("Error signing out from Firebase:", error);
                }
        
                resetStates();
                localStorage.removeItem('authToken');
                document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.brainhealthpro.ca;";
                document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
                document.cookie = "isLoggedOut=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.brainhealthpro.ca;";
                document.cookie = "isLoggedOut=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
                console.log("User is logged out, resetting states and clearing authToken");
            }
        };

        auth.onAuthStateChanged(async res => {
            checkLogout();
            await fetchUser(res);
        });

        return () => {
            if (unsubParticipant) unsubParticipant();
            if (unsubAdmin) unsubAdmin();
        };
    }, []);

    return { currentAdmin, currentParticipant, currentWeek, fetchingUser, loading, setLoading, deletedUser, setDeletedUser, resetStates };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
