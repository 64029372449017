import { useCallback, useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import { auth, functions } from "../../../firebase/firebase";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { httpsCallable } from "firebase/functions";
import { useLocation } from "react-router-dom";

export const SubDomainRedirection = () => {
    const query = useQuery();
    const { currentParticipant, fetchingUser } = useFetchUser();

    const getCookie = (name: string): string | undefined => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift();
    };

    // Function to check if we should redirect
    const shouldRedirect = useCallback((): boolean => {
        // Don't redirect if still fetching user
        if (fetchingUser) {
            console.log("Not redirecting: Still fetching user");
            return false;
        }
    
        // Don't redirect if logged out
        const isLoggedOut = getCookie('isLoggedOut') === 'true' || localStorage.getItem('isLoggedOut') === 'true';
        if (isLoggedOut) {
            console.log("Not redirecting: User is logged out");
            return false;
        }
    
        // Don't redirect if we're processing a completion
        const processingCompletion = localStorage.getItem('processingCompletion') === 'true';
        if (processingCompletion) {
            // Check if we've been processing for too long (over 30 seconds)
            const startTime = parseInt(localStorage.getItem('completionStartTime') || '0', 10);
            const currentTime = Date.now();
            const processingTime = currentTime - startTime;
            
            if (processingTime > 30000) {
                // If processing for too long, clear the flag and allow redirection
                console.log("Completion processing timeout exceeded, allowing redirection");
                localStorage.removeItem('processingCompletion');
                localStorage.removeItem('completionStartTime');
            } else {
                console.log("Not redirecting: Processing completion", { processingTime: processingTime / 1000 });
                return false;
            }
        }
    
        // Don't redirect if on completion page
        const isCompletedUrl = window.location.href.includes("completed");
        if (isCompletedUrl) {
            console.log("Not redirecting: On completion page");
            return false;
        }
    
        // Don't redirect if no participant or study
        if (!currentParticipant?.id || !currentParticipant.study) {
            console.log("Not redirecting: No participant or study");
            return false;
        }
    
        return true;
    }, [fetchingUser, currentParticipant]);

    // Function to manually trigger redirection
    const redirectToSubdomain = useCallback(async (force: boolean = false): Promise<boolean> => {
        if (!force && !shouldRedirect()) {
            return false; // Don't redirect
        }

        if (!currentParticipant?.id || !currentParticipant.study) {
            console.log("Cannot redirect: No participant or study");
            return false;
        }

        try {
            const tokenQuery = query.get("token");
            const authToken = getCookie('authToken') || localStorage.getItem('authToken');

            if (authToken) {
                console.log("Redirecting with existing auth token");
                if (currentParticipant.study === "synergic2") {
                    window.location.href = `https://syn2.brainhealthpro.ca/home`;
                } else if (currentParticipant.study === "sleep") {
                    window.location.href = `https://sleep-library.brainhealthpro.ca/home`;
                }
                return true;
            }

            if (!tokenQuery) {
                console.log("Creating new auth token");
                const createCustomToken = httpsCallable(functions, "createCustomToken");
                const tokenPayload = await createCustomToken({ uid: currentParticipant.id });
                const newAuthToken = tokenPayload.data as string;
                
                // Store token in both localStorage and cookie
                localStorage.setItem("authToken", newAuthToken);
                // document.cookie = `authToken=${newAuthToken}; path=/; max-age=3600;`;
                
                // Redirect based on study
                if (currentParticipant.study === "synergic2") {
                    window.location.href = `https://syn2.brainhealthpro.ca/?token=${newAuthToken}`;
                } else if (currentParticipant.study === "sleep") {
                    window.location.href = `https://sleep-library.brainhealthpro.ca/?token=${newAuthToken}`;
                }
                return true;
            }
        } catch (error) {
            console.error("Error during redirection:", error);
            return false;
        }
        
        return false;
    }, [currentParticipant, query, shouldRedirect]);

    return {
        redirectToSubdomain,
        shouldRedirect,
        isReady: !fetchingUser && currentParticipant?.id && !!currentParticipant.study
    };
};